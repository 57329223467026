import React from "react";
import { Link, graphql, useStaticQuery } from "gatsby";
import SEO from "../components/SEO";
import Layout from "../components/Layout";
import styled from "styled-components";
import FAQ from "../components/FrequentlyAskedQ";
import Breadscrum from "../components/Breadscrum";
import LinkButton from "../components/pohrby/LinkButton";
import CallButton from "../components/pohrby/CallButton";
import OverenaFirma from "../components/pohrby/OverenaFirma";
import Img from "gatsby-image";
import { useMediaQuery } from 'react-responsive';
import SwiperCore, { Navigation} from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import Balon from "../assets/img/balon.svg";
import Coin from "../assets/img/coin.svg";
import Heart from "../assets/img/heart.svg";
import Jo from "../assets/img/pricingCheck.svg";
import SupportPanel from "../components/SupportPanel";
import RecenzePohreb from "../components/pohrby/RecenzePohreb";
import Next from "../assets/img/next.svg";
import Prev from "../assets/img/prev.svg";
import Klauni from "../assets/img/funeralClowns.png";
import PoSvem from "../components/pohrby/PoSvem";
import Jumbotron from "../components/pohrby/Jumbotron";
import Features from "../components/pohrby/Features";
import NaseSluzby from "../components/pohrby/NaseSluzby";
import Postup from "../components/pohrby/Postup";
import KdeFungujeme from "../components/pohrby/KdeFungujeme";
import Press from "../components/pohrby/Press";
import SeVsimPomuzeme from "../components/pohrby/SeVsimPomuzeme";
import StructuredHodnoceni from "../components/pohrby/StucturedHodnoceni";
import Block from "../components/Block";
import ObradniSine from "../components/pohrby/ObradniSine";
import DalsiOdkazy from "../components/pohrby/DalsiOdkazy";


SwiperCore.use([Navigation]);



const RegionyTemplateInner = styled.div`


  .jumboWrap {
    .isMain {
      .big {
        max-width: 1000px;
        font-size: ${props => props.smallH1 ?  "45px" : "55px"};
        margin-top: 15px;
        margin-bottom: 30px !important;

        @media (max-width: 899px) {
          font-size: 37px;
        }
      }

      p {
        max-width: 1000px !important;
      }
    }

    .jumboPress {
      max-width: 1150px !important;
    }
  }

  .recenze {
    .leftInner {
      p {
        font-size: 23px !important;
        line-height: 35px !important;

        @media (max-width: 899px) {
          font-size: 17px !important;
          line-height: 26px !important;

        }
      }
    }
  }

  .faqs {
    padding-top: 0 !important;
  }


  .breadscrum {
    @media (max-width: 899px) {
      width: fit-content;
      margin-left: 0;
    }
  }


`;






const RegionyTemplate = ({
  zadnyObrad,
  liteVerze,

  name,
  slug,
  seoTitle,
  seoDesc,
  ratingCount,
  faqData,

  h1,
  smallH1,
  perex,
  review,
  reviewName,
  reviewPic,

  uvodH2,
  uvodText,
  mapLocation,

  postupHeading1,
  postupHeading2,
  postupHeading3,

  postupH1,
  postupText1,
  postupText2,
  postupText3,

  seVsimPomuzemeText,

  featuresH2,
  featuresText1,
  featuresText2,
  featuresText3,

  kremaceText,
  kremaceCena,
  kremaceSluzby,

  pohrebText,
  pohrebCena,
  pohrebSluzby,

  netradicniText,
  netradicniCena,
  netradicniSluzby,
  
  obradniSine,
  obradniSineH2,

  dalsiOdkazy,
}) => {
  const isPlus470 = useMediaQuery({
    query: '(min-width: 470px)'
  });


  const isLess1050 = useMediaQuery({
    query: '(max-width: 1050px)'
  });

  let data = useStaticQuery(graphql`
    query {

      big: file(relativePath: { eq: "pohrebPozadi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      small: file(relativePath: { eq: "pohrebPozadiSmall.png" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      recenzeSample: file(relativePath: { eq: "zakaznice4.png" }) {
        childImageSharp {
          fixed(width: 350) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }

      postup1: file(relativePath: { eq: "postup1.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      postup2: file(relativePath: { eq: "postup2.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      postup3: file(relativePath: { eq: "postup3.png" }) {
        childImageSharp {
          fluid(maxWidth: 420) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      recenzeSampleSmall: file(relativePath: { eq: "zakaznice4Small.png" }) {
        childImageSharp {
          fixed(width: 80) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }

      pohrbyJumbo: file(relativePath: { eq: "pohrbyJumbo.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      naseSluzby: file(relativePath: { eq: "naseSluzbyPozadi.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1920) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }

      cremationTip: file(relativePath: { eq: "cremationTip.png" }) {
        childImageSharp {
          fixed(width: 350) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }

      cremationTipSmall: file(relativePath: { eq: "cremationTip.png" }) {
        childImageSharp {
          fixed(width: 300) {
            ...GatsbyImageSharpFixed_noBase64
          }
        }
      }
    }
  `);


  let structuredData = {
    "@context": "https://schema.org",
    "@type": "Product",
    "name": name,
    "brand":"Goodbye.cz",
    "image":"https://goodbye.cz/rakev.jpg",
    "description": seoDesc,
    "aggregateRating": {
      "@type":"AggregateRating",
      "bestRating": "5",
      "ratingCount": ratingCount,
      "ratingValue": 4.9,
      "worstRating": "1"}
  };
  

  return (
    <Layout>
      <SEO
        title={seoTitle}
        customTitle
        description={seoDesc}
        image="/ogImage.png"
      />
      
      <script type="application/ld+json" dangerouslySetInnerHTML={{__html: JSON.stringify(structuredData)}}/>

      
      <RegionyTemplateInner smallH1={smallH1}>

        

        <Jumbotron
          isMain
          isRegion
          h1={h1}
          p={perex}
          pozadi={data.big.childImageSharp.fluid}
          pozadiSmall={data.small.childImageSharp.fluid}
          recenzeText={review ? review : <>Byli jsme se službami velice spokojeni: rychlost, profesionalita, ochota a vstřícnost. <span>Tento přístup byl pro nás v této těžké situaci skutečnou pomocí.</span></>}
          recenzeImg={reviewPic ? reviewPic : data.recenzeSample.childImageSharp.fixed}
          recenzeImgFixed
          recenzeImgSmall={reviewPic ? reviewPic : data.recenzeSampleSmall.childImageSharp.fixed}
          zakaznikJmeno={reviewName ? reviewName : <>Hana V.</>}
          zakaznikKdo={"Zákaznice"}
          breadscrum={
            <Breadscrum
              visible={true}
              stranky={[
                {name: "Domů", url: "/"},
                {name: "Pohřební služba", url: "/pohrebni-sluzba/"},
                {name: name, url: `/${slug}/`},
              ]}
            />
          }
        />

        <Features
          liteVerze
          customH2={featuresH2}
          text1={featuresText1}
          text2={featuresText2}
          text3={featuresText3}
        />

        

        <NaseSluzby
          h2={uvodH2}
          text={uvodText}
          mapLocation={mapLocation}
          zadnyObrad={zadnyObrad}

          kremaceText={kremaceText}
          kremaceCena={kremaceCena}
          kremaceSluzby={kremaceSluzby}

          pohrebText={pohrebText}
          pohrebCena={pohrebCena}
          pohrebSluzby={pohrebSluzby}

          netradicniText={netradicniText}
          netradicniCena={netradicniCena}
          netradicniSluzby={netradicniSluzby}
        />

        <Postup
          postupH1={postupH1 ? postupH1 : "Jaký je postup?"}
        
          fotka1={data.postup1.childImageSharp.fluid}
          heading1={postupHeading1 ? postupHeading1 : "Nezávazně si zavoláme a probereme vaši situaci"}
          text1={postupText1 ? postupText1 : "Chápeme, že smrt blízkého je situace, kterou neplánujete. Pokud nám zavoláte, jsme připraveni vaši situaci okamžitě řešit. Nebo se můžete jednoduše zeptat na cokoliv, co vás napadne. Na telefonu jsme pro vás každý den, ve dne v noci."}

          fotka2={data.postup2.childImageSharp.fluid}
          heading2={postupHeading2 ? postupHeading2 : "Pohřeb s námi naplánujete z bezpečí domova"}
          text2={postupText2 ? postupText2 : <>Na plánování budete mít dostatek času. Nemusíte nikam chodit. Vše s vámi vyřešíme po telefonu a on-line (výběr <Link to="/poradna/smutecni-kytice-pohreb/">květin</Link>, <Link to="/rakve/">rakve</Link>, parte a podobně). Zvládnete to tak bez zbytečného tlaku a stresu. Zbytek můžete nechat na nás. Celou dobu je vám naše pohřební služba k dipozici a připravena pomoci.</>}

          fotka3={data.postup3.childImageSharp.fluid}
          heading3={postupHeading3 ? postupHeading3 : "O vše ostatní se už postaráme my"}
          text3={postupText3 ? postupText3 : "Ať už si vyberete jakýkoliv pohřeb, my jsme tu pro vás, abychom vyřešili formality na matrice, oblečení zesnulého i jeho úpravu, stav smuteční síně a další povinnosti. Postaráme se, aby poslední rozloučení proběhlo tak, jak jste to plánovali."}
        />

        {obradniSine && 
          <ObradniSine
            sine={obradniSine}
            h2={obradniSineH2}
          />
        }

        <RecenzePohreb 
          customMesto={mapLocation}
        />

        <Press />

        <SeVsimPomuzeme
          customText={seVsimPomuzemeText}
        />

        <KdeFungujeme />

        <FAQ className={"faqs"} data={faqData}/>

        {dalsiOdkazy && <DalsiOdkazy data={dalsiOdkazy}/> }

      </RegionyTemplateInner>
    </Layout>
  );
}


export default RegionyTemplate;


